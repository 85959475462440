@use "../../styles/Variables" as v;

.error__boundary {
  height: 100vh;
  width: 100%;
}

.error__boundary .flex__column__container {
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
}

.error__boundary .flex__column__container .header {
  height: 80px;
  padding: 16px;

  img {
    max-height: 50px;
    width: auto;
  }
}

.error__boundary .flex__column__container .container {
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30vh;

  h1 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 16px;
  }

  button {
    background-color: white;
    border: 1px solid v.$heading-color;
    color: v.$heading-color;
  }
}
