@use "./Variables" as v;
@use "./Editor";
@use "./flexGrid/main.scss";

.app__container {
  padding-top: 4em;
  width: 100%;
}

.logo img {
  max-height: 48px;
  width: auto;
  height: auto;
}

.intro__logo {
  max-width: 64px;
  height: auto;
}

.inner__container.demogs {
}

//
//@ Right Image
//

@keyframes load-dot-1 {
  0% {
    top: 0px;
    opacity: 1;
  }
  20% {
    top: 32px;
  }
  50% {
    top: 32px;
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  80% {
    top: 80px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 0;
  }
}
@keyframes load-dot-2 {
  0% {
    top: 0px;
    opacity: 0;
  }
  9% {
    top: 0px;
    opacity: 0;
  }
  10% {
    top: 0px;
    opacity: 1;
  }
  30% {
    top: 32px;
  }
  50% {
    top: 32px;
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  80% {
    top: 80px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 0;
  }
}
@keyframes load-dot-3 {
  0% {
    top: 0px;
    opacity: 0;
  }
  19% {
    top: 0px;
    opacity: 0;
  }
  20% {
    top: 0px;
    opacity: 1;
  }
  40% {
    top: 32px;
  }
  50% {
    top: 32px;
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  80% {
    top: 80px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 0;
  }
}
.load-dot-container {
  width: 100px;
  height: 100px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.load-dot-1 {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: v.$emerald-500;
  position: relative;
  display: inline-block;
  left: 16px;
  top: 0px;
  animation: load-dot-1 2.5s infinite;
}
.load-dot-2 {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: v.$emerald-500;
  position: relative !important;
  display: inline-block;
  left: 28px;
  animation: load-dot-2 2.5s infinite;
}
.load-dot-3 {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: v.$emerald-500;
  position: relative;
  display: inline-block;
  left: 40px;
  animation: load-dot-3 2.5s infinite;
}

.newcode {
  background-color: v.$blue-500;
  margin: 0px;
}

.p-newcode {
  font-size: 0.875em;
  cursor: pointer;
  margin-top: 16px;
  text-align: right;

  &:hover {
    color: #ef8c9d;
  }
}
