@use './Variables' as v;
@use '../components/errorBoundary/ErrorBoundary';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap&amp;subset=latin-ext');

html,
body {
  height: 100%;
  background-color: #f9fafb;

  scroll-behavior: smooth;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  &::after,
  &::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}

i {
  font-style: italic;
}

span,
li,
a,
small,
label,
textarea,
input,
p {
  font-family: 'Inter', sans-serif;
  color: #4b5563;

  a {
    color: v.$blue-600;
  }
}

p.small {
  font-size: 0.875em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #24292e;
  font-family: 'Inter', sans-serif;
}

h1 {
  font-size: 2.125em;
  font-weight: 700;
  letter-spacing: -1.2px;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 0.875em;
}

h5 {
  font-size: 0.875em;
  font-weight: 600;
}

h6 {
  font-size: 0.875em;
  font-weight: 500;
  text-transform: uppercase;
  color: v.$blueGray-400;
}

form label {
  color: #374151;
  font-size: 0.875em;
  font-weight: 500;
}

.container {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 0 8px;
}

/* input styles */
.input {
  display: block;
  border: 1px solid rgb(209, 213, 219);
  height: 38px;
  width: 100%;
  border-radius: 6px;
  background-color: white;
  padding: 8px 24px 8px 16px;
  font-size: 0.875em;
  color: black;

  &::placeholder {
    color: v.$gray-500;
  }

  &.error {
    background-color: v.$red-200;
    border-color: v.$red-400;

    &::placeholder {
      color: v.$red-400;
    }
  }
}

.input__container {
  width: 100%;
  position: relative;
  min-height: 38px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
    gap: 8px;

    svg,
    p {
      color: white;
      font-weight: 700;
    }
  }
}

input[type='submit'] {
  background-color: #ef8c9d;
  min-width: 68px;
  height: 38px;
  padding: 8px 12px;
  color: white;
  border-radius: 6px;
  font-size: 1em;
  transition: color 300ms ease-in-out, background-color 300ms ease-in-out, border-color 300ms ease-in-out;
  width: 100%;
  cursor: pointer;
  border: none;
  font-weight: 700;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 36px;
  align-items: center;

  .color-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
  }
}

button {
  display: block;
  background-color: #ef8c9d;
  min-width: 68px;
  height: 38px;
  padding: 8px;
  color: white;
  border-radius: 6px;
  border: none;
  font-weight: 600;
  font-size: 0.875em;
  transition: color 300ms ease-in-out, background-color 300ms ease-in-out, border-color 300ms ease-in-out;

  a {
    color: white;
    text-decoration: none;
    font-weight: 700;
  }
}

.inner__container {
  max-width: 512px;
  margin: 0 auto;
  padding: 0px 24px;
}
// Assuming this is the holy one

.input--invalid {
  background-color: v.$red-200 !important;
  border-color: v.$red-400 !important;
}

input::placeholder {
  color: #9ca8bb;
  font-size: 1em;
}

select {
  border-radius: 6px;
  padding: 4px 6px;
}

select.input {
  height: 40px;
}

.input__label {
  color: #374151;
  font-size: 0.875em;
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input__group {
  padding-top: 32px;
  padding-bottom: 32px;
}

.input__group .btn {
  width: 90%;
  margin: auto;
  max-width: 300px;
  display: block;
}

.input + .input__label {
  margin-top: 16px;
}

.input + .btn,
.input__label + .btn {
  margin-top: 16px;
}

.input + .button {
  margin-top: 16px;
}

.input__help-text {
  display: block;
  font-size: 12px;
  color: var(--dark-grey);
}

.input--invalid {
  border-color: var(--error-red);
}

.input__label--invalid {
  color: var(--error-red);
}

.input__label--invalid .input__help-text {
  color: var(--error-red);
}

/* button styles */
.button {
  display: block;
  background-color: v.$blueGray-800;
  min-width: 68px;
  height: 42px;
  padding: 8px 16px;
  color: white;
  border-radius: 6px;
  border: none;
  font-size: 1em;
  transition: color 300ms ease-in-out, background-color 300ms ease-in-out, border-color 300ms ease-in-out;
  margin-top: 16px;
  width: 100%;
}

.button:hover {
  cursor: pointer;
  background-color: v.$emerald-500;
}

.button:disabled {
  cursor: not-allowed;
  background-color: v.$gray-200;
  color: white;
}
.button--full-width {
  width: 100%;
}

.button--small {
  font-size: 14px;
  padding: 4px;
}

.button--inverse {
  background-color: white;
  color: var(--very-dark-grey);
  min-width: 100px;
}

.button--inverse:hover {
  background-color: var(--bright-pink);
  color: white;
}

.form__wrapper {
  border-radius: 12px;
  background-color: white;
  border: 1px solid v.$warmGray-100;
  padding: 36px;
  -webkit-box-shadow: 0px 10px 30px -12px rgba(82, 82, 82, 0.2);
  -moz-box-shadow: 0px 10px 30px -12px rgba(82, 82, 82, 0.2);
  box-shadow: 0px 10px 30px -12px rgba(82, 82, 82, 0.2);

  @include v.tablet {
    padding: 24px 16px;
  }

  .form__footer {
    p {
      font-size: 0.875em;
    }
  }
}
